/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-mutable-exports */
// import REAR_BIN_MAPPING from './rear-bin-mapping';

// import Cryptr from 'cryptr';
import CryptoJS from 'crypto-js';
import masterMapping from '../common/masterMapping';

// const _cryptr = new Cryptr(process.env.REACT_APP_SESSION_CIPHER);
const secertKey = process.env.REACT_APP_SESSION_CIPHER;

let init = () => {
  console.log('Board not connected');
};

let updatePaymentStatus;

let ipcRendererObj = null;
let openBin = (binId) => {
  console.log(`openBin called with ${binId}`);
  console.log('Board not connected');
  // eslint-disable-next-line no-use-before-define
  openBinCommand(binId);
};
let openMultipleBins = (binIds) => {
  console.log(`openMultipleBins called with ${binIds}`);
  console.log('Board not connected');
  // eslint-disable-next-line no-use-before-define
  openBinCommand(binIds);
};
let openMiniBins = (binIds) => {
  console.log(`openMiniBins called with ${binIds}`);
  console.log('Board not connected');
  // eslint-disable-next-line no-use-before-define
  openMiniBinCommand(binIds);
};
let writeCoolTemp = (value) => {
  console.log(`writeCoolTargetTemp with ${value}`);
  console.log('Board not connected');
};

let writeSettings = (FileObj) => {
  console.log(`writeSettings called with ${JSON.stringify(FileObj)}`);
};

let writeInitSettings = (FileObj) => {
  console.log(`writeInitSettings called with ${JSON.stringify(FileObj)}`);
};

let readSettings = () => {
  console.log('readSettings called to get settings');
};

let WritePickupInfo = (pickupObject) => {
  console.log(`WritePickupInfo called with ${JSON.stringify(pickupObject)}`);
};

let WriteInitPickupInfo = (pickupObject) => {
  console.log(`WriteInitPickupInfo called with ${JSON.stringify(pickupObject)}`);
};

let readPickupInfos = () => {
  console.log('readSettings called to get settings');
};

let forceBinStatusCheck = () => {
  console.log('forceBinStatusCheck called to force check the door status');
};

let POSHandler = (event, args) => {
  console.log(`event ${event} and args ${args}`);
};

let closeApplication = () => {
  console.log('Not running in electronjs');

  let n = sessionStorage.length;
  while (n--) {
    const key = sessionStorage.key(n);
    if (key) {
      sessionStorage.removeItem(key);
    }
  }
};

let authRequest = (transactionId, totalAmount, transactions) => {
  console.log(
    `writeToPOS called with command :  ${
      (transactionId, totalAmount, transactions)
    }`,
  );
  console.log('Board not connected');
};

if (window.electron) {
  const { ipcRenderer, remote } = window.electron;
  if (ipcRenderer) ipcRendererObj = ipcRenderer;

  closeApplication = () => {
    remote.getCurrentWindow().close();

    let n = sessionStorage.length;
    while (n--) {
      const key = sessionStorage.key(n);
      if (key) {
        sessionStorage.removeItem(key);
      }
    }
  };

  writeSettings = (FileObj) => {
    const command = JSON.stringify({
      command: 'write',
      FileObj,
    });

    if (ipcRendererObj) {
      ipcRendererObj.send('kiosk-to-main', command);
    }
  };

  writeInitSettings = (FileObj) => {
    const command = JSON.stringify({
      command: 'write-init',
      FileObj,
    });

    if (ipcRendererObj) {
      ipcRendererObj.send('kiosk-to-main', command);
    }
  };

  WritePickupInfo = (pickupObject) => {
    const command = JSON.stringify({
      command: 'write-pickup-info',
      pickupObject,
    });

    if (ipcRendererObj) {
      ipcRendererObj.send('kiosk-to-main', command);
    }
  };

  WriteInitPickupInfo = (pickupObject) => {
    const command = JSON.stringify({
      command: 'write-pickup-info-init',
      pickupObject,
    });

    if (ipcRendererObj) {
      ipcRendererObj.send('kiosk-to-main', command);
    }
  };

  readSettings = () => {
    if (ipcRendererObj) {
      const fileData = ipcRenderer.sendSync('readSettings', 'read');
      return fileData;
    }
    return null;
  };

  readPickupInfos = () => {
    if (ipcRendererObj) {
      const pickupData = ipcRenderer.sendSync('readPickupInfo', 'read');
      return pickupData;
    }
    return null;
  };

  forceBinStatusCheck = () => {
    if (ipcRendererObj) {
      const fileData = ipcRenderer.sendSync('forceBinStatusCheck', 'all');
      return fileData;
    }
    return null;
  };

  openMiniBins = (binIds) => {
    const _activeSession = localStorage.getItem('ActiveSession');
    // const decryptedSessionObject = _cryptr.decrypt(_activeSession);
    const bytes = CryptoJS.AES.decrypt(_activeSession, secertKey);
    const decryptedSessionObject = bytes.toString(CryptoJS.enc.Utf8);
    const parseddecryptedSessionObject = JSON.parse(decryptedSessionObject);
    if (
      parseddecryptedSessionObject
      && parseddecryptedSessionObject._session_active === true
    ) {
      const command = JSON.stringify({
        command: 'open-mini-multiple-bins',
        binIds,
      });
      if (ipcRendererObj) {
        ipcRendererObj.send('kiosk-to-main', command);
      }
      console.log(command);
    }
  };

  const openRemoteBin = (binId) => {
    const command = JSON.stringify({
      command: 'open-bin',
      binId,
    });
    console.log('openBin-Front:', binId);
    if (ipcRendererObj) {
      ipcRendererObj.send('kiosk-to-main', command);
    }
    // window.consultSocket
    //   && window.consultSocket.emit
    //   && window.consultSocket.emit('KioskOpenBinSuccess', {
    //     bin: binId,
    //     box_id,
    //   });
    console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$', command);
  };

  openBin = (binId, box_id = undefined, type = undefined) => {
    if (type === undefined) {
      const _activeSession = localStorage.getItem('ActiveSession');
      // const decryptedSessionObject = _cryptr.decrypt(_activeSession);
      const bytes = CryptoJS.AES.decrypt(_activeSession, secertKey);
      const decryptedSessionObject = bytes.toString(CryptoJS.enc.Utf8);
      const parseddecryptedSessionObject = JSON.parse(decryptedSessionObject);
      if (
        parseddecryptedSessionObject
        && parseddecryptedSessionObject._session_active === true
      ) {
        let rearStock;
        if (window.electron) {
          const fileData = JSON.parse(readSettings());
          rearStock = fileData ? fileData.rearStock : '';
        } else {
          rearStock = !!(
            localStorage.getItem('rearStock')
            && (localStorage.getItem('rearStock') === 'true'
              || localStorage.getItem('rearStock') === -true)
          );
        }
        if (rearStock) {
          console.log('Before:', binId);
          // binId = REAR_BIN_MAPPING[binId];
          console.log('openBin-RearStock:', binId);
        } else {
          console.log('openBin-Front:', binId);
        }
        openRemoteBin(binId, box_id);
      }
    } else {
      openRemoteBin(binId, box_id);
    }
  };

  openMultipleBins = (binIds) => {
    const _activeSession = localStorage.getItem('ActiveSession');
    // const decryptedSessionObject = _cryptr.decrypt(_activeSession);
    const bytes = CryptoJS.AES.decrypt(_activeSession, secertKey);
    const decryptedSessionObject = bytes.toString(CryptoJS.enc.Utf8);
    const parseddecryptedSessionObject = JSON.parse(decryptedSessionObject);
    if (
      parseddecryptedSessionObject
      && parseddecryptedSessionObject._session_active === true
    ) {
      const command = JSON.stringify({
        command: 'open-multiple-bins',
        binIds,
      });
      if (ipcRendererObj) {
        ipcRendererObj.send('kiosk-to-main', command);
      }
      console.log(command);
    }
  };

  writeCoolTemp = (temperature) => {
    const command = JSON.stringify({
      command: 'set-target-temp',
      temperature,
    });
    if (ipcRendererObj) {
      ipcRendererObj.send('kiosk-to-main', command);
    }
    console.log(command);
  };

  authRequest = (
    transactionId,
    amount,
    transactions,
    updatePaymentStatusFunction,
  ) => {
    const command = JSON.stringify({
      command: 'auth-request',
      transactionId,
      amount,
      transactions,
    });
    updatePaymentStatus = updatePaymentStatusFunction;
    if (ipcRendererObj) {
      ipcRendererObj.send('kiosk-to-main', command);
    }
    console.log(command);
  };

  POSHandler = (event, args) => {
    console.log(args);
    // eslint-disable-next-line default-case
    switch (args.event) {
      case 'target-temperature-value':
        window.targetTempValue = args.data;
        break;
      case 'target-temperature-diff-value':
        window.targetTempDiffValue = args.data;
        break;
      case 'current-temperature':
        if (window.updateTemp) {
          window.updateTemp(args);
        }
        break;
      case 'swipe-expected':
        console.log(
          `SWIPE-EXPECTED is ${JSON.stringify(
            event,
          )} and args are ${JSON.stringify(args)}`,
        );
        updatePaymentStatus(
          'swipe-expected',
          'Please tap, insert, or swipe your card below.',
        );
        break;
      case 'authorizing':
        console.log(
          `AUTHORIZING is ${JSON.stringify(
            event,
          )} and args are ${JSON.stringify(args)}`,
        );
        updatePaymentStatus('authorizing', 'Authorizing...');
        break;
      case 'transaction':
        if (
          args.transctionApproved === true
          || args.transctionApproved === 'true'
        ) {
          console.log('TRANSACTION RESULT: Approved');
          updatePaymentStatus('approved', 'Payment received.', args);
        } else {
          console.log('TRANSACTION RESULT: Declined');
          updatePaymentStatus('declined', 'Transaction failed.');
        }
        console.log(
          `event is ${JSON.stringify(event)} and args are ${JSON.stringify(
            args,
          )}`,
        );
        break;
      case 'current-board-data':
        console.log(`Current board data to kisok: ${JSON.stringify(args)}`);
        if (window.getBoardData) {
          window.getBoardData(args);
        }
        break;
    }
  };

  init = () => {
    if (ipcRendererObj) {
      ipcRendererObj.on('main-to-kiosk', (event, arg) => {
        POSHandler(event, arg);
      });
      console.log('Board initialized');
    } else {
      console.log('Board not initialized');
      console.log(ipcRendererObj);
    }
  };
}

window.writeCoolTemp = writeCoolTemp;

const pad = (num, size) => {
  let s = `${num}`;
  while (s.length < size) s = `0${s}`;
  return s;
};

const openMiniBinCommand = (binId) => {
  let command = '';
  command += '0D';
  if (/^.*?[0-9]$/.test(binId[1]) === true) {
    const column = binId[0];
    if (column === 'A' || column === 'B' || column === 'C') {
      const row = parseInt(
        binId[1] + (typeof binId[2] !== 'undefined' ? binId[2] : ''),
        10,
      );
      command += 'AA00';
      switch (column) {
        case 'A':
          command += pad(row, 2);
          break;
        case 'B':
          command += pad(row + 5, 2);
          break;
        case 'C':
          command += pad(row + 10, 2);
          break;
        default:
          break;
      }
      command += 'F2';
      console.log('openMiniBin', binId, command);
    }
  }
  return command;
};

const openBinCommand = (binId) => {
  let command = '';
  command += '0D';
  if (/^.*?[0-9]$/.test(binId[1]) === true) {
    const column = binId[0];
    if (column === 'A' || column === 'B' || column === 'C' || column === 'D' || column === 'E') {
      const row = parseInt(
        binId[1] + (typeof binId[2] !== 'undefined' ? binId[2] : ''),
        10,
      );
      if (column === 'A' || column === 'B' || column === 'C') {
        command += 'AA';
      } else {
        command += '55';
      }
      command += '00';
      switch (column) {
        case 'A':
          command += pad(row, 2);
          break;
        case 'B':
          command += pad(row + 5, 2);
          break;
        case 'C':
          command += pad(row + 10, 2);
          break;
        case 'D':
          command += pad(row, 2);
          break;
        case 'E':
          command += pad(row + 12, 2);
          break;
        default:
          break;
      }
      command += 'F2';
      console.log('openBin', binId, command);
    }
  } else {
    console.log('openBin', binId, masterMapping[binId]);
  }
  return command;
};

export {
  init,
  ipcRendererObj,
  openBin,
  writeSettings,
  readSettings,
  writeInitSettings,
  closeApplication,
  authRequest,
  writeCoolTemp,
  forceBinStatusCheck,
  WritePickupInfo,
  WriteInitPickupInfo,
  readPickupInfos,
  openMultipleBins,
  openMiniBins,
  openBinCommand,
  openMiniBinCommand,
};
